export const COMPANY_NAME_MAX_LIMIT = 100;
export const EMPLOYER_DEFAULT_MIN = 1;
export const DELAY_TIME = 3000;
export const COMPANY_SIZE_MAX_LIMIT = 1000000;

export const NUMERIC_PATTERN = /^\d+$/;
export const EMAIL_PATTERN = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const companyTypeList = [
  'Pvt Ltd',
  'LLP',
  'Partnership',
  // 'Propreitership',
];
export const daysList = ['10 days', '15 days', '30 days'];
export const weeksList = ['1st week', '2nd week', '3rd week', '4th week'];

export const daysListMap = {
  '10 days': 10,
  '15 days': 15,
  '30 days': 30,
};
export const weekListMap = {
  '1st week': 0,
  '2nd week': 1,
  '3rd week': 2,
  '4th week': 3,
};

export const companyTypeMap = {
  'Pvt Ltd': 'PRIVATE_LIMITED',
  LLP: 'LIMITED_LIABILITY_PARTNERSHIP',
  Partnership: 'PARTNERSHIP',
  // Propreitership: 'PROPRIETORSHIP',
};

export const EMPLOYER_TAB_HEADERS = ['Profile', 'Referral', 'Staff'];

export const emploerDetailsData = {
  firstName: 'Aman',
  lastName: 'Kumar',
  'Phone No.': '+91 98787XXXXX',
  emailId: 'aman.kumar@gmail.com',
  address: 'Noida Sector 27, B - Block, C - 72, Uttar Pradesh, - 201309',
  companyType: 'Pvt LTD',
  companyName: 'ABC Private Limited',
  createdOn: '12 June 2023',
  createdAt: '10:45 AM',
};

export const EMPLOYER_BUSINESS_VERIFICATION_WORKFLOW = {
  gst: {
    WORKFLOW: 'GST_VERIFICATION',
    STATUS_KEY: 'gstVerification',
    TYPE: 'GST',
  },
  llpin: {
    WORKFLOW: 'LLPIN_VERIFICATION',
    STATUS_KEY: 'llpinVerification',
    TYPE: 'LLPIN',
  },
  pan: {
    WORKFLOW: 'PAN_VERIFICATION',
    STATUS_KEY: 'panVerification',
    TYPE: 'PAN',
  },
  cin: {
    WORKFLOW: 'CIN_VERIFICATION',
    STATUS_KEY: 'cinVerification',
    TYPE: 'CIN',
  },
};

export const BUSINESS_VERIFICATION_HEADERS = [
  'Verification Type',
  'Document No.',
  'Last Updated Date',
  'Verification Status',
];

export const BUSINESS_VERIFICATION_HEADERS_TYPE = [
  'TEXT',
  'TEXT',
  'DATE_TIME',
  'DOCUMENT_VERIFICATION_TAG',
];
export const BANK_HEADERS = [
  'Account Holder Name',
  'Bank Name',
  'Account no.',
  'Verification Status',
];

export const BANK_HEADERS_TYPE = [
  'TEXT',
  'TEXT',
  'TEXT',
  'DOCUMENT_VERIFICATION_TAG',
];
export const AGREEMENT_HEADERS = ['Signing Status', 'Date'];

export const AGREEMENT_HEADERS_TYPE = ['TEXT', 'DATE_TIME'];

export const REFERRAL_HEADERS = ['Degree', 'Count', 'Earning'];

export const REFERRAL_HEADERS_TYPE = ['DEGREE', 'TEXT', 'AMOUNT'];
export const BUSINESS_VERIFICATION_LINKS = {
  GST: '',
};

export const BUSINESS_TYPES = {
  GST: 'GST',
  CIN: 'CIN',
  PAN: 'PAN',
  LLPIN: 'LLPIN',
};

export const BUSINESS_VERIFICATION_TEXTS = {
  gst: {
    TITLE: 'GST Verification',
    SUB_TITLE: 'GST Number Verification',
  },
  cin: {
    TITLE: 'CIN Verification',
    SUB_TITLE: 'CIN Status',
  },
  pan: {
    TITLE: 'PAN Verification',
    SUB_TITLE: 'PAN Status',
  },
  llpin: {
    TITLE: 'LLPIN Verification',
    SUB_TITLE: 'LLPIN Status',
  },
};

export const TEST_OBJ_ERROR_STRUCTURE = {
  testName: false,
  testCategory: false,
};
export const DISPOSABLE_MAILS = [
  'mailinator.com',
  'yopmail.com',
  'guerrillamail.com',
  '10minutemail.com',
  'trashmail.com',
  'maildrop.cc',
  'temp-mail.org',
  'throwawaymail.com',
  'fakeinbox.com',
  'emailondeck.com',
];
